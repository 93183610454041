<template>
    <component
        v-if="(inlineEdit || mouseOver) && edit"
        :contenteditable="true"
        :is="element"
        @click="inlineEdit = true"
        @blur="event => inlineInput(event)"
        @mouseleave="onMouseOut"
        
    >{{target.value}}</component>
    <component
        v-else
        :contenteditable="false"
        :class="getClass"
        :is="element"
        :href="link"
        @mouseenter="onMouseEnter"
        @click="inlineEdit = true"
        v-html="target.value"
    />
</template>

<script>


    export default{
        props:['element', 'target', 'eleClass', 'link', 'mouseDisable'],
        data(){
            return {
                inlineEdit: false,
                edit: this.$store.state.globalEdit ? true : false,
                mouseOver: false,
                canToggle: true
            }
        },
        watch:{
            '$store.state.globalEdit'(){
                this.inlineEdit = false;
                this.edit = this.$store.state.globalEdit;
            },
            '$store.state.enterLock'(){
                if(this.$store.state.enterLock !== this){
                    this.mouseOver = false;
                }
            }
        },
        computed:{
            getClass(){
                if(this.eleClass){
                    return this.eleClass;
                }
                return "";
            }
        },
        methods:{
            inlineInput($event){
                this.inlineEdit = false;
                this.target.value = $event.target.innerText;
            },
            onMouseOut(){
                this.mouseOver = false;
            },
            onMouseEnter(){
                if(!this.mouseDisable){
                    this.$store.state.enterLock = this;
                    this.mouseOver = true;
                }
            }
        },
    }
</script>

<style>

    .bigger-text > *{
        font-size: 50px !important;
        min-height: 100px !important;
    }

</style>