<template>
      <div id="app" style="height:100%" class="default-page-style">
            <router-view/>
      </div>
</template>

<script>
      export default {
            name: 'App'
      }

</script>