<template>
      <div :class="'creation-container container-' +  container.tag ">
            <app-mod 
                  v-for="(mod, i) in container.modules" 
                  :mod="mod" 
                  :tag="container.tag"
                  :refId="i"
                  :key="i"/>
      </div>
</template>

<script>

      import Mod from "./Module.vue";

      export default {
            
            props: ["container"],
            components: {
                  appMod: Mod
            }
      }
      
</script>