export default{
    'HTML-Module':           ()   => import("@/components/modules/Html-Module.vue"),
    'Grid-Module':           ()   => import("@/components/modules/Grid-Module.vue"),
    'Panel-Slider-Module':   ()   => import("@/components/modules/Panel-Slider-Module.vue"),
    'Single-Image-Module':   ()   => import("@/components/modules/Single-Image-Module.vue"),
    'Google-Maps-Module':    ()   => import("@/components/modules/Google-Maps-Module.vue"),
    'Form-Module':           ()   => import("@/components/modules/Form-Module.vue"),
    /*
    *                   Legacy Modules below.
    */
    'Header-Module':         ()   => import("@/components/modules/legacy/Header-Module.vue"),
    'Basic-IT-Module':       ()   => import("@/components/modules/legacy/Basic-IT-Module.vue"),
    'Gallery-Module':        ()   => import("@/components/modules/legacy/Gallery-Module.vue"),
    'Slider-Module':         ()   => import("@/components/modules/legacy/Slider-Module.vue"),
    'TTI-Module':            ()   => import("@/components/modules/legacy/TTI-Module.vue"),
    'Footer-Module':         ()   => import("@/components/modules/legacy/Footer-Module.vue"),
    'Gallery-Slider-Module': ()   => import("@/components/modules/legacy/Gallery-Slider-Module.vue"),
    'Expand-Slider-Module':  ()   => import("@/components/modules/legacy/Expand-Slider-Module.vue"),
    'Select-Slider-Module':  ()   => import("@/components/modules/legacy/Select-Slider-Module.vue"),
    'Link-Gallery-Module':   ()   => import("@/components/modules/legacy/Link-Gallery-Module.vue"),
    'EFG-Footer-Module':     ()   => import("@/components/modules/legacy/EFG-Footer-Module.vue"),
}
