import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import * as VueGoogleMaps from "vue2-google-maps"

Vue.config.productionTip = false

import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyLoad from 'vue-lazyload';
import InlineEdit from "./components/helpers/Inline-Edit-Helper"

Vue.use(Vue2TouchEvents)
Vue.use(VueLazyLoad)

Vue.use(VueRouter)

import PISinglePage from './components/pages/PI-Single-Page.vue';
import CVPereira from './components/pages/CV-Pereira.vue';
import CVIovino from './components/pages/CV-Iovino.vue';


const scrollBehavior = function (to, from, savedPosition) {
  if(savedPosition){
    return savedPosition;
  }else{
    if (to.hash && from) {
      return {
        selector: to.hash
      }
    }
  }
}

const router = new VueRouter({
mode: 'history',
scrollBehavior,
routes: [
    {path:'/', component: PISinglePage},
{path:'/.html', component: PISinglePage},
{path:'/pereira', component: CVPereira},
{path:'/pereira.html', component: CVPereira},
{path:'/iovino', component: CVIovino},
{path:'/iovino.html', component: CVIovino},
{path:'*', component: PISinglePage},

]

})

Vue.use(VueGoogleMaps, {
  load: {
        key:"AIzaSyAEJ9f6QRhUzov-Xyvqc4Q7i29EGU3rJJU",
        libraries: "places"
  }
});

Vue.component('inline-edit', InlineEdit)

new Vue({
  render: h => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
