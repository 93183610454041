<template>
      <section :id="tag + '-' + refId" :class="'mod ' + mod.modname.toLowerCase() + ' ' + mod.modclass.toLowerCase()">
            <component 
                  :is="mod.modname" 
                  :config="mod.config"
            />
      </section>
</template>

<script>

    import modules from '@/store/module-import.js'
    export default {
        props: ["mod", "tag", "refId"],
        components: {
            ...modules
        }
    }
      
</script>
